// src/utils/apiClient.js
import { logger } from './logger'
import { useAuthStore } from '@/stores/auth' // Import the auth store

const isDevOrCapacitor =
  import.meta.env.MODE === 'development' ||
  import.meta.env.VITE_USE_NGROK_HEADER === 'true'

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL
const googleNativeUrl = import.meta.env.VITE_API_BASE_GOOGLENATIVE_URL
console.log('apiClient using API_BASE_URL:', apiBaseUrl)

async function fetchWrapper(
  method,
  url,
  payload = null,
  requiresAuth = true,
  isGoogleAuth = false
) {
  const headers = {}

  if (requiresAuth) {
    const authStore = useAuthStore()
    const token = authStore.token || localStorage.getItem('token')
    if (token) {
      headers.Authorization = `Bearer ${token}`
    } else {
      console.warn('No authentication token found for authenticated request.')
    }
  }

  // Add ngrok-skip-browser-warning header in development or Capacitor builds
  if (isDevOrCapacitor) {
    headers['ngrok-skip-browser-warning'] = 'true'
  }

  // Use googleNativeUrl for Google auth requests, otherwise use apiBaseUrl
  const baseUrl = isGoogleAuth ? googleNativeUrl : apiBaseUrl

  const options = {
    method,
    headers,
  }

  if (payload) {
    if (payload instanceof FormData) {
      options.body = payload
    } else {
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(payload)
    }
  }

  try {
    const response = await fetch(`${baseUrl}${url}`, options)

    if (!response.ok) {
      if (response.status === 401 && requiresAuth) {
        // Handle unauthorized error silently
        const authStore = useAuthStore()
        await authStore.logout()
        return null // Return null instead of throwing an error
      }
      const errorData = await response.json()
      throw new Error(
        errorData.message || `HTTP error! status: ${response.status}`
      )
    }

    return await response.json()
  } catch (error) {
    if (requiresAuth) {
      console.warn('API Error:', error)
      return null // Return null for auth-required requests that fail
    }
    if (import.meta.env.MODE !== 'production') {
      console.error('API Error:', {
        url,
        method,
        error: error.message,
        stack: error.stack,
      })
    }
    logger.error('API Error:', error)
    throw error
  }
}

export default {
  get(url, requiresAuth = true, isGoogleAuth = false) {
    return fetchWrapper('GET', url, null, requiresAuth, isGoogleAuth)
  },
  post(url, payload, requiresAuth = true, isGoogleAuth = false) {
    console.log('All params:', url, payload, requiresAuth, isGoogleAuth)
    return fetchWrapper('POST', url, payload, requiresAuth, isGoogleAuth)
  },
  put(url, payload, requiresAuth = true, isGoogleAuth = false) {
    return fetchWrapper('PUT', url, payload, requiresAuth, isGoogleAuth)
  },
  delete(url, requiresAuth = true, isGoogleAuth = false) {
    return fetchWrapper('DELETE', url, null, requiresAuth, isGoogleAuth)
  },
  async head(endpoint, options = {}) {
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }

      // Add authorization header if token exists
      const token = localStorage.getItem('token')
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }

      // Ensure the baseURL is properly formatted
      const url = endpoint.startsWith('http')
        ? endpoint
        : `${this.baseURL || ''}/api${endpoint}`

      const response = await fetch(url, {
        method: 'HEAD',
        headers,
        ...options,
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      return {
        ok: response.ok,
        status: response.status,
        headers: response.headers,
      }
    } catch (error) {
      console.error('HEAD request failed:', error)
      throw error
    }
  },
  async checkVersion(endpoint, options = {}) {
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Request-Type': 'version-check',
      }

      // Add authorization header if token exists
      const token = localStorage.getItem('token')
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }

      // Add ngrok header if needed
      if (isDevOrCapacitor) {
        headers['ngrok-skip-browser-warning'] = 'true'
      }

      // Ensure proper URL construction with forward slash
      const cleanEndpoint = endpoint.replace(/^\/+/, '') // Remove leading slashes
      const url = `${apiBaseUrl}/${cleanEndpoint}` // Add forward slash between base URL and endpoint

      console.debug('Checking version for URL:', url)

      const response = await fetch(url, {
        method: 'GET',
        headers,
        ...options,
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      return {
        ok: response.ok,
        status: response.status,
        headers: response.headers,
        lastModified: response.headers.get('last-modified'),
      }
    } catch (error) {
      console.warn('Version check failed:', error)
      throw error
    }
  },
  getUrl(endpoint) {
    const cleanEndpoint = endpoint.replace(/^\/+|\/+$/g, '')
    return `${apiBaseUrl}/${cleanEndpoint}`
  },
}
