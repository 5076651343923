// src/stores/auth.js
import { ref } from 'vue'
import { defineStore } from 'pinia'
import apiClient from '@/utils/apiClient'
import { createStoreLogger } from '@/utils/logger'
import { handleStoreError } from '@/utils/errorHandler'
import { useRouter } from 'vue-router'

const log = createStoreLogger('AuthStore')

export const useAuthStore = defineStore('auth', () => {
  const router = useRouter()
  const user = ref(null)
  const token = ref(localStorage.getItem('token'))
  const error = ref(null)
  const isLoading = ref(true)
  const requiresOnboarding = ref(false)
  const isAuthenticated = ref(false)

  function handleApiError(err) {
    error.value = err instanceof Error ? err.message : String(err)
    handleStoreError('AuthStore', 'API Error', err)
  }

  function authenticate(result) {
    if (result.token) {
      token.value = result.token
      localStorage.setItem('token', token.value)
    }
    if (result.user) {
      user.value = result.user
      requiresOnboarding.value = result.user.requiresOnboarding || false
      isAuthenticated.value = true
      log.info('Authentication successful', { userId: user.value.id })
    }
  }

  async function login(payload) {
    try {
      const result = await apiClient.post('/login', payload, false)
      authenticate(result)
      await me()
      log.info('Login successful')
    } catch (err) {
      error.value = 'Login failed. Please check your credentials and try again.'
      handleApiError(err)
      throw err
    }
  }

  async function loginWithNativeGoogle(idToken, inviteCode = null) {
    try {
      const payload = {
        idToken,
        ...(inviteCode && { inviteCode }),
      }

      const result = await apiClient.post(
        '/auth/google/native',
        payload,
        false,
        true
      )
      authenticate(result)
      await me()
      log.info('Native Google login successful')
    } catch (err) {
      error.value = 'Native Google authentication failed. Please try again.'
      handleApiError(err)
      throw err
    }
  }

  async function loginWithToken(
    newToken,
    type = 'bearer',
    isNative = false,
    router
  ) {
    try {
      log.info('Attempting token login', { type, isNative })
      localStorage.setItem('token', newToken)
      localStorage.setItem('tokenType', type)
      token.value = newToken

      let result
      try {
        result = isNative
          ? await apiClient.post(
              '/auth/google/native',
              { idToken: newToken },
              false,
              true
            )
          : await apiClient.get('/me')
      } catch (apiError) {
        throw new Error(
          apiError.response?.data?.message || 'Authentication failed'
        )
      }

      if (result && result.user) {
        user.value = result.user
        requiresOnboarding.value = result.user.requiresOnboarding || false
        isAuthenticated.value = true
        log.info('Token login successful', { userId: user.value.id })
      } else {
        throw new Error('User data not found in response')
      }

      if (router) {
        router.push({ name: 'stories' })
      }
    } catch (err) {
      log.error('Token login failed:', {
        message: err.message,
        status: err.response?.status,
        data: err.response?.data,
      })
      isAuthenticated.value = false
      user.value = null
      token.value = null
      localStorage.removeItem('token')
      localStorage.removeItem('tokenType')
      throw err
    }
  }

  async function logout() {
    try {
      await apiClient.delete('/logout')
      log.info('Logout successful')
    } catch (err) {
      log.warn('Logout error:', err)
    } finally {
      token.value = null
      user.value = null
      isAuthenticated.value = false
      localStorage.removeItem('token')
    }
  }

  async function me() {
    try {
      const result = await apiClient.get('/me')
      if (result.user) {
        user.value = result.user
        requiresOnboarding.value = result.user.requiresOnboarding || false
        isAuthenticated.value = true
        log.info('User data fetched successfully', { userId: user.value.id })
        return user.value
      }
      throw new Error('User data not found in response')
    } catch (err) {
      error.value = 'Failed to fetch user information.'
      if (err instanceof Error && err.message.includes('Unauthorized')) {
        await logout()
      }
      handleApiError(err)
      throw err
    }
  }

  async function register(payload) {
    try {
      await apiClient.post('/register', payload, false)
      log.info('Registration successful')
      return true
    } catch (err) {
      error.value = 'Registration failed. Please try again.'
      handleApiError(err)
      throw err
    }
  }

  async function registerWithGoogle(inviteCode) {
    try {
      const result = await apiClient.post(
        '/auth/google/register',
        { inviteCode },
        false
      )
      authenticate(result)
      await me()
      log.info('Google registration successful')
    } catch (err) {
      error.value = 'Registration with Google failed. Please try again.'
      handleApiError(err)
      throw err
    }
  }

  async function initAuth() {
    isLoading.value = true
    if (token.value) {
      try {
        await me()
        log.info('Auth initialized successfully')
      } catch (error) {
        log.warn('Failed to fetch user data:', error)
        isAuthenticated.value = false
        user.value = null
        token.value = null
        localStorage.removeItem('token')
      }
    } else {
      isAuthenticated.value = false
      user.value = null
    }
    isLoading.value = false
  }

  function clearError() {
    error.value = null
  }

  async function verifyEmail(verificationToken) {
    try {
      log.info('Attempting email verification with token:', verificationToken)
      const response = await apiClient.put(
        `/verify-email/${verificationToken}`,
        {},
        false
      )

      if (response?.user) {
        user.value = response.user
        if (response.token) {
          token.value = response.token
        }
        await router.push('/stories')
        log.info('Email verification successful')
        return true
      }

      error.value = 'Email verification failed'
      log.error('Verification failed - no user data in response')
      return false
    } catch (err) {
      error.value = 'Email verification failed'
      log.error('Email verification error:', err)
      handleApiError(err)
      return false
    }
  }

  async function activateAccount(tokenParam, password) {
    try {
      const result = await apiClient.put(
        `/activate-account/${tokenParam}`,
        { password },
        false
      )
      authenticate(result)
      await me()
      log.info('Account activation successful')
    } catch (err) {
      error.value = 'Failed to activate account. Please try again.'
      handleApiError(err)
      throw err
    }
  }

  async function forgotPassword(email) {
    try {
      log.info('Initiating password reset for:', { email })
      await apiClient.post('/forgot-password', { email }, false)
      log.info('Password reset email sent successfully')
    } catch (err) {
      error.value = 'Failed to send password reset email.'
      handleApiError(err)
      throw err
    }
  }

  async function resetPassword(tokenParam, password) {
    try {
      await apiClient.put(`/reset-password/${tokenParam}`, { password }, false)
      log.info('Password reset successful')
    } catch (err) {
      error.value = 'Failed to reset password.'
      handleApiError(err)
      throw err
    }
  }

  async function verifyResetToken(token) {
    try {
      await apiClient.get(`/verify-reset-token/${token}`, false)
      log.info('Reset token verified successfully')
    } catch (err) {
      error.value = 'Invalid or expired reset token.'
      handleApiError(err)
      throw err
    }
  }

  async function updateProfile(data) {
    try {
      log.info('Updating user profile', { updatedFields: Object.keys(data) })
      const result = await apiClient.put('/user/profile', data)
      if (result.user) {
        user.value = result.user
        log.info('Profile updated successfully', { userId: user.value.id })
        return user.value
      }
      throw new Error('User data not found in response')
    } catch (err) {
      handleStoreError('AuthStore', 'updateProfile', err)
      throw err
    }
  }

  return {
    user,
    token,
    error,
    isLoading,
    isAuthenticated,
    login,
    loginWithNativeGoogle,
    loginWithToken,
    register,
    registerWithGoogle,
    logout,
    me,
    initAuth,
    clearError,
    requiresOnboarding,
    verifyEmail,
    activateAccount,
    forgotPassword,
    resetPassword,
    verifyResetToken,
    updateProfile,
  }
})
