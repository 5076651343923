// frontend/src/main.js
import './assets/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import Vue3TouchEvents from 'vue3-touch-events'
import { handleError } from './utils/errorHandler'
import { logger } from './utils/logger'
import { useAuthStore } from './stores/auth'
import Notifications from '@kyvg/vue3-notification'
import * as Sentry from '@sentry/vue'
import { Capacitor } from '@capacitor/core'
import { StatusBar } from '@capacitor/status-bar'
import { SafeArea } from '@capacitor-community/safe-area'
import { SplashScreen } from '@capacitor/splash-screen'
import { createTransmit } from '@/services/transmit'

const app = createApp(App)
const pinia = createPinia()

// First initialize Pinia
app.use(pinia)

// Then we can use stores
const authStore = useAuthStore()
const transmit = createTransmit(() => authStore.token)

app.provide('transmit', transmit)
app.use(router)

// Initialize Sentry
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE,
    integrations: [],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', 'api.talebly.com'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

// App plugins
app.use(Notifications)
app.use(Vue3TouchEvents)

// Error handling
app.config.errorHandler = handleError
app.config.globalProperties.$log = logger

// Platform initialization
const initializePlatform = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      await StatusBar.setStyle({ style: 'dark' })
      await SafeArea.getSafeAreaInsets()
    } catch (error) {
      console.error('Platform initialization error:', error)
    }
  }
}

// Modified initialization sequence
Promise.all([router.isReady(), initializePlatform(), authStore.initAuth()])
  .then(async () => {
    app.mount('#app')
    try {
      await new Promise(resolve => setTimeout(resolve, 2000))
      await SplashScreen.hide({
        fadeOutDuration: 500,
      })
    } catch (error) {
      console.error('Error hiding splash screen:', error)
    }
  })
  .catch(error => {
    console.error('Failed to initialize application:', error)
    Sentry.captureException(error)
  })
