import { Capacitor } from '@capacitor/core'

export const isPlatform = {
  get isNative() {
    return Capacitor.isNativePlatform()
  },

  get isIos() {
    return Capacitor.getPlatform() === 'ios'
  },

  get isAndroid() {
    return Capacitor.getPlatform() === 'android'
  },

  get isWeb() {
    return !this.isNative
  },
}
