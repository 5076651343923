// src/utils/errorHandler.js
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'
import { useNotification } from '@kyvg/vue3-notification'
import { logger } from './logger'
import * as Sentry from '@sentry/vue'

export function handleError(error, component, info = {}) {
  // Ensure component and info are properly formatted
  const safeComponent = String(component || '')
  const safeInfo = {
    action: String(info?.action || 'handleError'),
    ...info,
  }

  const errorContext = {
    store: safeComponent,
    method: safeInfo.action,
  }

  // Always console.error in non-production
  if (import.meta.env.MODE !== 'production') {
    logger.error(errorContext, 'Error details:', {
      message: error?.message || 'Unknown error',
      stack: error?.stack,
      info: safeInfo,
    })
  }

  // Capture exception in Sentry with additional context
  Sentry.withScope(scope => {
    scope.setExtra('component', safeComponent)
    scope.setExtra('errorInfo', safeInfo)
    scope.setLevel('error')
    Sentry.captureException(error)
  })

  try {
    const auth = useAuthStore()
    const router = useRouter()
    const { notify } = useNotification()

    // Handle different types of errors
    if (error?.response) {
      // API Error handling
      const status = error.response.status
      const errorMap = {
        401: () => {
          auth.logout()
          router.push('/login')
          notify({
            type: 'error',
            title: 'Session Expired',
            text: 'Please log in again.',
          })
        },
        403: () => {
          router.push('/forbidden')
          notify({
            type: 'error',
            title: 'Access Denied',
            text: 'You do not have permission to perform this action.',
          })
        },
        404: () => {
          router.push('/not-found')
          notify({
            type: 'error',
            title: 'Not Found',
            text: 'The requested resource was not found.',
          })
        },
        default: () => {
          notify({
            type: 'error',
            title: 'Error',
            text:
              error.response.data?.message || 'An unexpected error occurred.',
          })
        },
      }

      const handler = errorMap[status] || errorMap.default
      handler()
    } else if (error?.request) {
      // Network Error
      notify({
        type: 'error',
        title: 'Network Error',
        text: 'Please check your internet connection and try again.',
      })
    } else {
      // Other Errors
      notify({
        type: 'error',
        title: 'Error',
        text: error?.message || 'An unexpected error occurred.',
      })
    }
  } catch (handlerError) {
    console.error('Error in error handler:', handlerError)
  }

  return error
}

// Helper function for store error handling
export function handleStoreError(storeName, actionName, error) {
  return handleError(error, storeName, { action: actionName })
}
