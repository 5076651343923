// src/stores/story.js
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import apiClient from '@/utils/apiClient'
import { useAuthStore } from '@/stores/auth'
import { createStoreLogger } from '@/utils/logger'
import { handleStoreError } from '@/utils/errorHandler'

// Add Web API type declarations
/* global caches, Response */

const log = createStoreLogger('StoryStore')

export const useStoryStore = defineStore('story', () => {
  const authStore = useAuthStore()
  const stories = ref([])
  const inProgressStories = ref([])
  const storyDetails = ref(null)
  const configOptions = ref({
    languages: [],
    storyTypes: [],
    writingStyles: [],
    readerAges: [],
    castMembers: [],
    voices: [],
  })
  const defaultConfig = ref({
    languageAttributeId: '',
    storyTypeAttributeId: '',
    writingStyleAttributeId: '',
    readerAgeAttributeId: '',
    numParagraphs: 10,
    prompt: '',
    defaultCastMemberIds: [],
    voiceId: '',
  })
  const form = ref({
    config: {
      languageAttributeId: '',
      storyTypeAttributeId: '',
      writingStyleAttributeId: '',
      readerAgeAttributeId: '',
      numParagraphs: 5,
      prompt: '',
      castMemberIds: [],
      voiceId: '',
      perspective: 'third',
      firstPersonCharacterId: null,
    },
  })
  const loading = ref(false)
  const error = ref(null)
  const success = ref(null)
  const subscription = ref(null)

  // Add a loading state tracker
  const loadingStates = ref(new Map())

  // Add a fetch queue to prevent duplicate requests
  const fetchQueue = new Map()

  // Add a helper to normalize URLs for caching

  // Add near the top of the store
  const hasInitialized = ref(false)

  // Add new method for adding/updating stories
  function addOrUpdateStory(story) {
    const index = stories.value.findIndex(s => s.id === story.id)
    if (index === -1) {
      // Only animate new stories
      stories.value.push({
        ...story,
        isNew: true, // Add flag for animation
      })
    } else {
      updateStoryInPlace(story)
    }
  }

  // Update the fetchStories method to handle incremental updates
  async function fetchStories({ lastUpdate = 0 } = {}) {
    try {
      const response = await apiClient.get('/stories', {
        params: { lastUpdate },
      })

      if (response) {
        // Return the stories without updating the store directly
        return response
      }
      return []
    } catch (err) {
      handleStoreError('StoryStore', 'fetchStories', err)
      throw err
    }
  }

  async function fetchInProgressStories() {
    try {
      const response = await apiClient.get('/stories/in-progress')
      inProgressStories.value = response || []
      log.info('In-progress stories fetched', {
        count: inProgressStories.value.length,
      })
    } catch (err) {
      handleStoreError('StoryStore', 'fetchInProgressStories', err)
      inProgressStories.value = []
    }
  }

  async function fetchStoryDetails(id) {
    // Add check for valid ID
    if (!id) {
      console.warn('Invalid story ID provided to fetchStoryDetails')
      return null
    }

    // Check if already loading this story
    if (loadingStates.value.get(id)) {
      return await new Promise(resolve => {
        const existingQueue = fetchQueue.get(id) || []
        fetchQueue.set(id, [...existingQueue, resolve])
      })
    }

    loadingStates.value.set(id, true)
    try {
      // First try to get from cache
      const cachedStory = await getCachedStory(id)
      if (cachedStory) {
        // Ensure the cached story has all required fields
        if (!cachedStory.audioUrl) {
          // If missing critical fields, fetch fresh data
          const response = await apiClient.get(`/stories/${id}`)
          if (response) {
            await cacheStory(id, response)
            return response
          }
        }
        return cachedStory
      }

      const response = await apiClient.get(`/stories/${id}`)
      if (response) {
        // Validate response has required fields
        if (!response.audioUrl) {
          console.error('Story response missing audioUrl:', response)
          throw new Error('Invalid story data received')
        }

        // Cache the story
        await cacheStory(id, response)

        // Resolve any pending requests
        const queued = fetchQueue.get(id) || []
        queued.forEach(resolve => resolve(response))
        fetchQueue.delete(id)

        return response
      }
    } catch (err) {
      handleStoreError('StoryStore', 'fetchStoryDetails', err)
      throw err // Propagate error to caller
    } finally {
      loadingStates.value.delete(id)
    }
    return null
  }

  // Add caching helpers
  async function getCachedStory(id) {
    if ('caches' in window) {
      try {
        const cache = await caches.open('talebly-stories-v1')
        const normalizedUrl = `/api/stories/${id}`
        const response = await cache.match(normalizedUrl)
        if (response) {
          return response.json()
        }
      } catch (error) {
        console.warn('Failed to get cached story:', error)
        return null
      }
    }
    return null
  }

  async function cacheStory(id, data) {
    if ('caches' in window) {
      try {
        const cache = await caches.open('talebly-stories-v1')
        const normalizedUrl = `/api/stories/${id}`
        await cache.put(
          normalizedUrl,
          new Response(JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' },
          })
        )
      } catch (error) {
        console.warn('Failed to cache story:', error)
        // Fail silently - caching is a progressive enhancement
      }
    }
  }

  async function fetchConfigOptions() {
    loading.value = true
    try {
      configOptions.value = await apiClient.get('/stories/config-options')
      log.info('Story config options fetched', {
        languagesCount: configOptions.value.languages.length,
        storyTypesCount: configOptions.value.storyTypes.length,
      })
    } catch (err) {
      handleStoreError('StoryStore', 'fetchConfigOptions', err)
      error.value = 'Failed to fetch configuration options'
      throw err
    } finally {
      loading.value = false
    }
  }

  async function createStory() {
    loading.value = true
    error.value = null
    try {
      await apiClient.post('/stories/initiate', form.value)
      log.info('Story creation initiated', {
        language: form.value.config.languageAttributeId,
        storyType: form.value.config.storyTypeAttributeId,
      })
    } catch (err) {
      handleStoreError('StoryStore', 'createStory', err)
      error.value = 'Failed to initiate story creation'
      throw err
    } finally {
      loading.value = false
    }
  }

  function updateFormValue(key, value) {
    form.value.config[key] = value
    log.debug('Story form updated', { key, value })
  }

  async function subscribeToStoryUpdates(transmit) {
    if (!transmit) {
      log.error('Transmit instance not provided')
      return
    }

    try {
      // Clean up existing subscription without checking isDeleted
      if (subscription.value) {
        await subscription.value.delete().catch(() => {})
        subscription.value = null
      }

      const channel = `user/${authStore.user.id}/stories`
      const newSubscription = transmit.subscription(channel)

      newSubscription.onMessage(data => {
        log.info('Received story update:', data)
        // Add this switch case to handle in-progress stories
        switch (data.type) {
          case 'story_creation_initiated':
            inProgressStories.value = [
              ...inProgressStories.value,
              {
                id: data.progressId,
                currentStage: data.currentStage,
                status: data.status,
              },
            ]
            break
          case 'story_progress_updated':
            inProgressStories.value = inProgressStories.value.map(story =>
              story.id === data.progressId
                ? {
                    ...story,
                    currentStage: data.currentStage,
                    status: data.status,
                  }
                : story
            )
            break
          case 'story_created':
          case 'story_updated':
            fetchStories()
            fetchInProgressStories()
            break
          case 'story_deleted':
            fetchStories()
            break
        }
      })

      await newSubscription.create()
      subscription.value = newSubscription
      log.info('Subscribed to story updates', { channel })
    } catch (error) {
      log.error('Failed to subscribe to story updates:', error)
      throw error
    }
  }

  async function unsubscribeFromStoryUpdates() {
    if (subscription.value) {
      try {
        await subscription.value.delete().catch(() => {}) // Ignore any errors during deletion
        log.info('Unsubscribed from story updates')
      } catch (error) {
        log.error('Failed to unsubscribe from story updates:', error)
      } finally {
        subscription.value = null
      }
    }
  }

  async function clearGeneratingStories() {
    try {
      const inProgressIds = inProgressStories.value.map(story => story.id)
      await apiClient.post('/stories/clear-generating', { ids: inProgressIds })
      log.info('Generating stories cleared', { count: inProgressIds.length })
      await fetchInProgressStories()
      await fetchStories()
    } catch (err) {
      handleStoreError('StoryStore', 'clearGeneratingStories', err)
      throw err
    }
  }

  const completedStories = computed(() =>
    stories.value
      .filter(story => story.title)
      .map(story => ({
        ...story,
        language: story.storyConfig?.language?.name || 'Unknown',
        type: story.storyConfig?.storyType?.name || 'Unspecified',
        writingStyle: story.storyConfig?.writingStyle?.name || 'Unspecified',
        readerAge: story.storyConfig?.readerAge?.name || 'Unspecified',
        bookCoverUrl:
          story.bookCoverUrl || '/placeholder.svg?height=300&width=300',
      }))
  )

  const isFormValid = computed(() => {
    const { config } = form.value
    const isValid =
      config.languageAttributeId &&
      config.storyTypeAttributeId &&
      config.writingStyleAttributeId &&
      config.readerAgeAttributeId &&
      config.voiceId &&
      config.numParagraphs >= 5 &&
      config.numParagraphs <= 25 &&
      config.prompt.trim() !== '' &&
      (config.perspective === 'third' ||
        (config.perspective === 'first' &&
          config.firstPersonCharacterId !== null))

    log.debug('Form validation status:', { isValid, config })
    return isValid
  })

  async function loadCachedStories() {
    // Skip if already initialized
    if (hasInitialized.value && stories.value.length > 0) {
      return { stories: stories.value }
    }

    try {
      const storiesResponse = await apiClient.get('/stories', {
        useCache: true,
        skipVersionCheck: true,
        forceCacheOnOffline: true,
        timeout: 1000,
      })

      if (storiesResponse) {
        stories.value = storiesResponse
        hasInitialized.value = true
        return { stories: storiesResponse }
      }
      return null
    } catch (err) {
      handleStoreError('StoryStore', 'loadCachedStories', err)
      return null
    }
  }

  function resetForm() {
    form.value = {
      config: {
        languageAttributeId: '',
        storyTypeAttributeId: '',
        writingStyleAttributeId: '',
        readerAgeAttributeId: '',
        numParagraphs: 5,
        prompt: '',
        castMemberIds: [],
        voiceId: '',
        perspective: 'third',
        firstPersonCharacterId: null,
      },
    }
  }

  // Add new methods to the store
  function setStoriesWithoutAnimation(newStories) {
    stories.value = newStories
  }

  function updateStoryInPlace(updatedStory) {
    const index = stories.value.findIndex(s => s.id === updatedStory.id)
    if (index !== -1) {
      // Update properties individually to maintain reactivity
      Object.assign(stories.value[index], updatedStory)
    }
  }

  return {
    stories,
    inProgressStories,
    storyDetails,
    configOptions,
    defaultConfig,
    form,
    loading,
    error,
    success,
    fetchStories,
    fetchInProgressStories,
    fetchStoryDetails,
    fetchConfigOptions,
    createStory,
    subscribeToStoryUpdates,
    unsubscribeFromStoryUpdates,
    clearGeneratingStories,
    completedStories,
    updateFormValue,
    isFormValid,
    loadCachedStories,
    resetForm,
    addOrUpdateStory,
    setStoriesWithoutAnimation,
    updateStoryInPlace,
    hasInitialized,
  }
})
