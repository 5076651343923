<template>
  <div class="app-wrapper h-full">
    <ErrorBoundary>
      <RouterView v-slot="{ Component }">
        <component :is="getLayoutComponent(Component)" v-if="Component">
          <Transition
            :name="route.meta.transition === 'none' ? '' : 'fade'"
            :mode="route.meta.transition === 'none' ? '' : 'out-in'"
          >
            <component :is="Component" :key="$route.fullPath" />
          </Transition>
        </component>
      </RouterView>
    </ErrorBoundary>

    <!-- Global Audio Player -->
    <audio
      ref="audioPlayer"
      class="hidden"
      preload="none"
      @ended="handleAudioEnded"
      @timeupdate="handleTimeUpdate"
      @loadedmetadata="handleLoadedMetadata"
    />
    <notifications position="top right" />

    <!-- Offline Status Indicator -->
    <div
      v-if="!isOnline"
      class="fixed bottom-0 left-0 right-0 bg-yellow-500 text-white p-2 text-center z-50"
    >
      You are currently offline. Some features may be limited.
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ErrorBoundary from '@/components/ErrorBoundary.vue'
import { useQueueStore } from '@/stores/queue'
import { useAuthStore } from '@/stores/auth'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { Capacitor } from '@capacitor/core'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { useNetworkStatus } from '@/composables/useNetworkStatus'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const queueStore = useQueueStore()
const audioPlayer = ref(null)
const { isOnline } = useNetworkStatus()

// Update getLayoutComponent to check meta first, then component layout
const getLayoutComponent = Component => {
  // First check route meta
  if (route.meta?.layout) {
    return route.meta.layout
  }
  // Then check component layout
  if (Component?.type?.layout) {
    return Component.type.layout
  }
  // Finally fallback to default
  return DefaultLayout
}

// Platform detection
const isCapacitor = computed(() => Capacitor.isNativePlatform())

onMounted(async () => {
  if (isCapacitor.value) {
    try {
      await ScreenOrientation.lock({ orientation: 'portrait' })
      console.log('Screen orientation locked to portrait')
    } catch (error) {
      console.error('Error locking screen orientation:', error)
    }
  }

  if (authStore.isAuthenticated && audioPlayer.value) {
    await queueStore.setAudioPlayer(audioPlayer.value)
  }

  router.isReady().then(() => {
    // isRouteReady.value = true
  })
})
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Add these new styles */
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
  }
}

/* Ensure background color extends under status bar */
html {
  background-color: rgb(var(--muted));
  min-height: 100vh;
  padding-top: var(--safe-area-inset-top, 0);
}

body {
  background-color: rgb(var(--muted));
  min-height: 100vh;
  margin: 0;
}

.app-wrapper {
  min-height: 100vh;
  background-color: rgb(var(--muted));
  padding-top: env(safe-area-inset-top, 0);
  -webkit-tap-highlight-color: transparent;
}
</style>
