// src/stores/queue.js
import { ref, watch, reactive, onUnmounted } from 'vue'
import { defineStore } from 'pinia'
import apiClient from '@/utils/apiClient'
import debounce from 'lodash/debounce'
import { DateTime } from 'luxon'
import { createStoreLogger } from '@/utils/logger'
import { handleStoreError } from '@/utils/errorHandler'
import { useStoryStore } from './story'

// Add Web API type declarations
/* global Audio */

const log = createStoreLogger('QueueStore')

export const useQueueStore = defineStore('queue', () => {
  const storyStore = useStoryStore()
  const queue = ref([])
  const currentlyPlaying = ref(null)
  const audioPlayer = ref(null)
  const isPlaying = ref(false)
  const isShuffleOn = ref(false)
  const isRepeatOn = ref(false)
  const isMuted = ref(false)
  const isDragging = ref(false)
  const isLoading = ref(false)
  const error = ref(null)
  let updateInterval = null

  function saveStateToLocalStorage() {
    const state = {
      currentlyPlaying: currentlyPlaying.value,
      isPlaying: false,
      isShuffleOn: isShuffleOn.value,
      isRepeatOn: isRepeatOn.value,
      isMuted: isMuted.value,
      timestamp: DateTime.now().toMillis(),
    }
    localStorage.setItem('queueState', JSON.stringify(state))
    log.debug('Queue state saved to localStorage')
  }

  function loadStateFromLocalStorage() {
    const savedState = localStorage.getItem('queueState')
    if (savedState) {
      const state = JSON.parse(savedState)
      const currentTime = Date.now()
      const oneMinute = 60 * 1000

      const savedTimestamp =
        typeof state.timestamp === 'number'
          ? state.timestamp
          : parseInt(state.timestamp, 10)

      if (currentTime - savedTimestamp < oneMinute) {
        currentlyPlaying.value = state.currentlyPlaying || null
        isPlaying.value = state.isPlaying || false
        isShuffleOn.value = state.isShuffleOn || false
        isRepeatOn.value = state.isRepeatOn || false
        isMuted.value = state.isMuted || false

        if (currentlyPlaying.value && audioPlayer.value) {
          audioPlayer.value.src = currentlyPlaying.value.audioUrl
          audioPlayer.value.currentTime = currentlyPlaying.value.progress || 0
        }
        log.info('Queue state loaded from localStorage')
        return true
      }
      log.info('Saved queue state expired')
    }
    return false
  }

  async function loadPlaybackState() {
    const loadedFromLocalStorage = loadStateFromLocalStorage()
    log.info('Loading playback state', {
      fromLocalStorage: loadedFromLocalStorage,
    })

    try {
      const response = await apiClient.get('/user/playback-state')
      queue.value = []

      for (const queueItem of response.queue || []) {
        queue.value.push(queueItem)
      }

      if (!loadedFromLocalStorage && response.currentlyPlaying) {
        const story = await fetchStoryById(response.currentlyPlaying.id)
        if (story) {
          currentlyPlaying.value = reactive({
            ...story,
            progress: response.currentlyPlaying.progress || 0,
            isPlaying: false,
          })

          if (audioPlayer.value) {
            audioPlayer.value.src = story.audioUrl
            audioPlayer.value.currentTime =
              response.currentlyPlaying.progress || 0
          }
          log.info('Current track loaded from server', { storyId: story.id })
        } else {
          currentlyPlaying.value = null
          log.warn('Failed to load current track from server')
        }
      }
    } catch (error) {
      handleStoreError('QueueStore', 'loadPlaybackState', error)
    }
  }

  const updatePlaybackState = async () => {
    if (currentlyPlaying.value && audioPlayer.value) {
      try {
        const playbackState = {
          currentlyPlaying: {
            storyId: currentlyPlaying.value.id,
            progress: audioPlayer.value.currentTime,
          },
          queue: queue.value.map(item => ({ id: item.id })),
        }
        await apiClient.put('/user/playback-state', playbackState)
        saveStateToLocalStorage()
        log.debug('Playback state updated', {
          storyId: currentlyPlaying.value.id,
          progress: audioPlayer.value.currentTime,
        })
      } catch (error) {
        handleStoreError('QueueStore', 'updatePlaybackState', error)
      }
    }
  }

  const debouncedUpdatePlaybackState = debounce(updatePlaybackState, 1000)

  function startUpdateInterval() {
    if (!updateInterval) {
      updateInterval = setInterval(debouncedUpdatePlaybackState, 5000)
      log.debug('Started playback state update interval')
    }
  }

  function stopUpdateInterval() {
    if (updateInterval) {
      clearInterval(updateInterval)
      updateInterval = null
      log.debug('Stopped playback state update interval')
    }
  }

  async function playPrevious() {
    if (currentlyPlaying.value && audioPlayer.value.currentTime > 3) {
      audioPlayer.value.currentTime = 0
      log.info('Restarted current track')
    } else if (currentlyPlaying.value) {
      log.info('No previous track available')
    }
  }

  async function playNext() {
    if (queue.value.length > 0) {
      const nextStory = queue.value.shift()
      log.info('Playing next track', { storyId: nextStory.id })
      await play(nextStory.id)
      await removeFromQueue(nextStory.id)
    } else {
      pause()
      currentlyPlaying.value = null
      log.info('Queue ended')
    }
  }

  async function play(storyId) {
    try {
      isLoading.value = true
      error.value = null

      // If no storyId is provided, try to play/resume current story
      if (!storyId && currentlyPlaying.value) {
        storyId = currentlyPlaying.value.id
      }

      // Validate storyId
      if (!storyId) {
        throw new Error('No story ID provided')
      }

      // Initialize audio player if needed
      initializeAudioPlayer()

      // If it's the same story that's currently loaded, just resume playback
      if (currentlyPlaying.value?.id === storyId && audioPlayer.value.src) {
        try {
          await audioPlayer.value.play()
          log.info('Resumed playing story:', { id: storyId })
          return
        } catch (playError) {
          console.error('Resume play error:', playError)
          // If resume fails, try reloading the story
        }
      }

      // Fetch story details for new story
      const storyDetails = await storyStore.fetchStoryDetails(storyId)
      if (!storyDetails?.audioUrl) {
        throw new Error('Story audio URL not found')
      }

      // Update currently playing
      currentlyPlaying.value = storyDetails

      // Set new audio source
      audioPlayer.value.src = storyDetails.audioUrl

      // Start playing
      try {
        await audioPlayer.value.play()
        log.info('Started playing story:', { id: storyId })
      } catch (playError) {
        console.error('Play error:', playError)
        throw new Error('Failed to start playback')
      }
    } catch (err) {
      error.value = err.message
      console.error('Error playing story:', err)
      throw err // Propagate error for handling in components
    } finally {
      isLoading.value = false
    }
  }

  function pause() {
    if (audioPlayer.value) {
      audioPlayer.value.pause()
      isPlaying.value = false
    }
  }

  function togglePlayPause() {
    if (isPlaying.value) {
      pause()
    } else {
      play()
    }
  }

  async function setAudioPlayer(player) {
    audioPlayer.value = player
    if (currentlyPlaying.value && player) {
      player.src = currentlyPlaying.value.audioUrl
      player.currentTime = currentlyPlaying.value.progress || 0
    }
    setupAudioPlayerListeners()
    await loadPlaybackState()
    log.info('Audio player initialized')
  }

  function onTimeUpdate() {
    if (currentlyPlaying.value && audioPlayer.value) {
      currentlyPlaying.value.progress = audioPlayer.value.currentTime
      saveStateToLocalStorage()
    }
  }

  function setupAudioPlayerListeners() {
    if (audioPlayer.value) {
      audioPlayer.value.addEventListener('play', onPlay)
      audioPlayer.value.addEventListener('pause', onPause)
      audioPlayer.value.addEventListener('ended', onAudioEnded)
      audioPlayer.value.addEventListener('timeupdate', onTimeUpdate)
      audioPlayer.value.addEventListener('error', onAudioError)
      log.debug('Audio player listeners set up')
    }
  }

  function removeAudioPlayerListeners() {
    if (audioPlayer.value) {
      audioPlayer.value.removeEventListener('play', onPlay)
      audioPlayer.value.removeEventListener('pause', onPause)
      audioPlayer.value.removeEventListener('ended', onAudioEnded)
      audioPlayer.value.removeEventListener('timeupdate', onTimeUpdate)
      audioPlayer.value.removeEventListener('error', onAudioError)
      log.debug('Audio player listeners removed')
    }
  }

  function onPlay() {
    isPlaying.value = true
    if (currentlyPlaying.value) currentlyPlaying.value.isPlaying = true
    startUpdateInterval()
    log.debug('Play event handled')
  }

  function onPause() {
    isPlaying.value = false
    if (currentlyPlaying.value) currentlyPlaying.value.isPlaying = false
    stopUpdateInterval()
    updatePlaybackState()
    log.debug('Pause event handled')
  }

  function onAudioEnded() {
    isPlaying.value = false
    if (currentlyPlaying.value) currentlyPlaying.value.isPlaying = false
    if (queue.value.length > 0) {
      playNext()
    } else {
      currentlyPlaying.value = null
      stopUpdateInterval()
      updatePlaybackState()
    }
    log.info('Track ended')
  }

  async function playNow(storyId) {
    await removeFromQueue(storyId)
    pause()
    await play(storyId)
    log.info('Playing track immediately', { storyId })
  }

  function onAudioError(e) {
    log.error('Audio playback error:', e)
    handleStoreError(
      'QueueStore',
      'audioPlayback',
      new Error('Audio playback failed')
    )
  }

  function addToQueue(story) {
    if (!queue.value.some(item => item.id === story.id)) {
      queue.value.push(story)
    }
  }

  async function removeFromQueue(storyId) {
    try {
      queue.value = queue.value.filter(item => item.id !== storyId)
      await apiClient.delete(`/user/queue/remove/${storyId}`)
      await updatePlaybackState()
      log.info('Track removed from queue', { storyId })
    } catch (error) {
      handleStoreError('QueueStore', 'removeFromQueue', error)
    }
  }

  function clearQueue() {
    queue.value = []
    updatePlaybackState()
    log.info('Queue cleared')
  }

  function toggleShuffle() {
    isShuffleOn.value = !isShuffleOn.value
    log.info('Shuffle toggled', { isOn: isShuffleOn.value })
  }

  function toggleRepeat() {
    isRepeatOn.value = !isRepeatOn.value
    log.info('Repeat toggled', { isOn: isRepeatOn.value })
  }

  function toggleMute() {
    if (audioPlayer.value) {
      audioPlayer.value.muted = !audioPlayer.value.muted
      isMuted.value = audioPlayer.value.muted
      log.info('Mute toggled', { isMuted: isMuted.value })
    }
  }

  async function fetchStoryById(id) {
    try {
      const response = await apiClient.get(`/stories/${id}`)
      const audioUrlResponse = await apiClient.get(`/audio/story/${id}/url`)
      log.debug('Story fetched', { storyId: id })
      return {
        ...response,
        audioUrl: audioUrlResponse.url,
        bookCoverUrl:
          response.bookCoverUrl || '/placeholder.svg?height=32&width=32',
      }
    } catch (error) {
      handleStoreError('QueueStore', 'fetchStoryById', error)
      return null
    }
  }

  function initializeAudioPlayer() {
    if (!audioPlayer.value) {
      audioPlayer.value = new Audio()

      // Add event listeners
      audioPlayer.value.addEventListener('play', () => {
        isPlaying.value = true
      })

      audioPlayer.value.addEventListener('pause', () => {
        isPlaying.value = false
      })

      audioPlayer.value.addEventListener('error', e => {
        console.error('Audio player error:', e)
        error.value = 'Failed to play audio'
        isPlaying.value = false
      })
    }
  }

  watch(audioPlayer, (newPlayer, oldPlayer) => {
    if (oldPlayer) removeAudioPlayerListeners()
    if (newPlayer) setupAudioPlayerListeners()
  })

  onUnmounted(() => {
    stopUpdateInterval()
    removeAudioPlayerListeners()
    log.debug('Queue store unmounted')
  })

  // Clean up function
  function cleanupAudio() {
    if (audioPlayer.value) {
      audioPlayer.value.pause()
      audioPlayer.value.src = ''
      audioPlayer.value = null
    }
  }

  return {
    queue,
    currentlyPlaying,
    audioPlayer,
    isPlaying,
    isShuffleOn,
    isRepeatOn,
    isMuted,
    isDragging,
    isLoading,
    error,
    play,
    pause,
    togglePlayPause,
    setAudioPlayer,
    addToQueue,
    removeFromQueue,
    clearQueue,
    playNow,
    playNext,
    playPrevious,
    toggleShuffle,
    toggleRepeat,
    toggleMute,
    loadPlaybackState,
    cleanupAudio,
  }
})
