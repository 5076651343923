import { Transmit } from '@adonisjs/transmit-client'

export const createTransmit = getToken => {
  return new Transmit({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    beforeSubscribe: async request => {
      try {
        const token = getToken()
        if (!token) {
          console.warn('No auth token available for Transmit subscription')
          return
        }

        // Ensure headers is a Headers instance
        if (!(request.headers instanceof globalThis.Headers)) {
          request.headers = new globalThis.Headers(request.headers)
        }

        request.headers.set('Authorization', `Bearer ${token}`)
      } catch (error) {
        console.error('Error in Transmit beforeSubscribe:', error)
      }
    },
    // Add error handling for connection issues
    onError: error => {
      console.error('Transmit connection error:', error)
    },
  })
}
